<template>
    <div>
        <div class="mainwidth">
            <div class="institution-top">
                <div class="institution-nav ">

                </div>
                <ul class="search-con" v-if="true">
                    <li>
                      <p class="p-one">机构类型：</p>
                      <p class="p-two">
                        <span :class="{'sp-active': jigouleixing === ''}" @click="jigouleixing = ''">不限</span>
                        <span v-for="(jItem, ji) in jLists" :key="ji" :class="{'sp-active': jigouleixing == jItem.BIANMA}" @click="jigouleixing = jItem.BIANMA">{{jItem.NAME}}</span>
                      </p>
                    </li>
                    <li>
                      <p class="p-one">排行方式：</p>
                      <p class="p-two">
                        <span :class="{'sp-active': paihangfangshi === ''}" @click="paihangfangshi = ''">不限</span>
                        <span :class="{'sp-active': paihangfangshi === 'FKED'}" @click="paihangfangshi = 'FKED'">受理额度</span>
<!--                        <span :class="{'sp-active': paihangfangshi === 'SPSX'}" @click="paihangfangshi = 'SPSX'">审批时效</span>-->
<!--                        <span :class="{'sp-active': paihangfangshi === 'SPCGL'}" @click="paihangfangshi = 'SPCGL'">审批成功率</span>-->
                        <span :class="{'sp-active': paihangfangshi === 'FWQYSL'}" @click="paihangfangshi = 'FWQYSL'">服务企业数量</span>
                      </p>
                    </li>
                    <li>
                      <a-button @click="onSearch">确定筛选</a-button>
                    </li>
                </ul>
            </div>
            <div class="institution-con">
                <a-table :columns="columns" :data-source="dList" :loading="loading" :pagination="pagination" @change="onPage"></a-table>
            </div>
        </div>
    </div>
</template>

<script>
const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    key: 'key',
    width: 80,
    align:'center'
  },
  {
    title: '机构名称',
    dataIndex: 'institution',
    key: 'institution',
    align:'center'
  },
  {
    title: '受理额度',
    dataIndex: 'Lending',
    key: 'Lending',
    align:'center'
  },
  {
    title: '审批时效',
    dataIndex: 'examine',
    key: 'examine',
    align:'center'
  },
  {
    title: '审批成果率',
    dataIndex: 'approvalSuccessRate',
    key: 'approvalSuccessRate',
    align:'center'
  },
  {
    title: '企业数量',
    dataIndex: 'number',
    key: 'number',
    align:'center'
  },
  {
    title: '融资额度',
    dataIndex: 'loanMoney',
    key: 'loanMoney',
    align:'center'
  },
]
export default {
  data() {
    return {
      columns,
      dList: [],
      page: 1,
      jigouleixing: '',
      paihangfangshi: '',
      jLists: [],
      pagination: {}
    }
  },
  methods: {
    onSearch () {
      this.page = 1
      this.total = 0
      this.getBankRepaList()
    },
    getBankRepaList () {
      this.dList = []
      this.$store.dispatch('getBankRepaList', {
        showCount: 10,
        currentPage: this.page,
        DEP_TYPE: this.jigouleixing,
        ORDER_BY_NAME: this.paihangfangshi,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result === 'success') {
          let datas = res.varList
          if (datas.length != 0) {
            this.pagination.total = res.page.totalResult
            for (let i in datas) {
              this.dList.push({
                key: parseInt(i) + 1 + ((this.page - 1) * 10),
                institution: datas[i].CNAME,
                Lending: datas[i].TOTAL_MONEY ? (datas[i].TOTAL_MONEY + '万') : '',
                examine: datas[i].SPSJ_DAYS ? datas[i].SPSJ_DAYS : '',
                approvalSuccessRate: datas[i].SPCGL ? (datas[i].SPCGL + '%') : '',
                number: datas[i].TOTAL_COUNT ? datas[i].TOTAL_COUNT : '',
                loanMoney: datas[i].LOAN_MONEY ? (datas[i].LOAN_MONEY + '万') : ''
              })
            }
          } else {
            this.dList = []
            this.pagination.total = 0
          }
        } else {
          this.dList = []
          this.pagination.total = 0
          this.$message.error('系统异常，获取列表失败，请稍后重试！')
        }
      })
    },
    // 翻页
    onPage (pagination) {
        this.page = pagination.current
        this.getBankRepaList()
    },
    getJiGouLeiXing () {
      this.$store.dispatch('getServiceItemList', { DICTIONARIES_ID: 'a2e4ff3ec1e64acda531ff1a99357437', tm: new Date().getTime() }).then(res => {
        if (res.result === 'success') {
          this.jLists = res.list
        } else {
          this.jLists = []
        }
      })
    }
  },
  created () {
    this.getBankRepaList()
    this.getJiGouLeiXing()
  }
}
</script>

<style lang="less" scoped>
.institution-nav ::v-deep(.ant-input){
  height: 30px;
  font-size:16px;
  width: 400px;
}
.search-con ::v-deep(.ant-btn){
    margin: 0 auto;
    padding:0 42px;
    color: #ffffff;
    height: 40px;
    background: #457dfe;
}
.institution-con ::v-deep(.ant-table-thead){
    background: #457dfe;
}
.institution-con ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}
</style>
<style lang="less" scoped>
.institution-top{
    margin-top: 30px;
    padding: 15px 10px;
    background-color:@color-ff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #929292;
    position: relative;
    font-size: @font-xl;
    .institution-nav{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ul{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0;
            li{
                height: 40px;
                line-height: 40px;
                padding: 0 30px;
                border: 1px solid @color-blue;
                margin: 0 20px;
                border-radius: 20px;
                cursor: pointer;
            }
            .liactive{
                color: @color-ff;
                background-color: @color-blue;
            }
            li:hover{
                color: @color-ff;
                background-color: @color-blue;
            }
        }
        div{
            margin-right: 20px;
        }
    }
    .search-con{
        margin-top: 15px;
        margin-bottom: 0;
        li{
            font-size: @font-lg;
            padding:10px 0px;
            border-bottom: @border-base;
            display: flex;
            justify-content: flex-start;
            p{
                margin-bottom: 0;
                font-size: @font-lg;
            }
            .p-one{
                width: 120px;
                padding-top: 2px;
                margin:5px 10px;
            }
            .p-two{
                width: 1156px;
                span{
                    display: inline-block;
                    padding: 2px 20px;
                    margin:5px 10px;
                    border-radius: 2px;
                    cursor: pointer;
                    transition: all .1s ease;
                    -moz-transition:all .1s ease; /* Firefox 4 */
                    -webkit-transition:all .1s ease; /* Safari 和 Chrome */
                    -o-transition:all .1s ease; /* Opera */
                }
                span:hover{
                    color: @color-ff;
                    background: @color-blue; /* 标准语法 */
                }
                .sp-active{
                    color: @color-ff;
                    background:@color-blue; /* 标准语法 */
                }
            }
        }
    }
}
.institution-top::before{
    content: '';
    position: absolute;
    left: -3px;
    top: 15px;
    width: 6px;
    height: 40px;
    background: @color-blue; /* 标准语法 */
}
.institution-top::after{
    content: '';
    position: absolute;
    right: -3px;
    top: 15px;
    width: 6px;
    height: 40px;
    background: @color-blue; /* 标准语法 */
}
.institution-con{
    margin-top: 30px;
    font-size: 16px;
}
</style>
